import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { COLORS } from './style';

const OverlayLineChart = ({ 
  selectedDataset, 
  filteredCovidData, 
  filteredSymptomsData, 
  selectedCounty,
  hoverPosition, 
  handleChartHover, 
  handleChartLeave, 
  startInterval, 
  endInterval
}) => {
  const [filteredChartData, setFilteredChartData] = useState([]);
  const [xPos, setXPos] = useState(null);
  const [hoveredData, setHoveredData] = useState(null);

  useEffect(() => {
    let combinedData = [];
    if (filteredSymptomsData && Array.isArray(filteredSymptomsData) && filteredSymptomsData.length > 0) {
      const firstEntry = filteredSymptomsData[0];

      selectedDataset.forEach(dataset => {
        if (firstEntry.data && firstEntry.data[dataset.value] !== undefined) {
          filteredSymptomsData.forEach(entry => {
            const value = entry.data[dataset.value];
            combinedData.push({ date: entry.date, [dataset.value]: value });
          });
        }
      });
    }

    const finalData = combinedData.map(entry => {
      const combinedEntry = { date: entry.date, ...entry };
      selectedDataset.forEach(dataset => {
        if (dataset.value === "Covid-19 Deaths") {
          const covidEntry = filteredCovidData.find(covidEntry => covidEntry.data_as_of === entry.date);
          combinedEntry[dataset.value] = covidEntry ? covidEntry.covid_death : 0;
        }
      });
      return combinedEntry;
    });

    setFilteredChartData(finalData);
  }, [filteredCovidData, filteredSymptomsData, selectedDataset]);

  useEffect(() => {
    if (hoverPosition && filteredChartData.length > 0) {
      const hoverDate = new Date(hoverPosition);
      let closestDataPoint = null;
      let minDiff = Infinity;

      filteredChartData.forEach(entry => {
        const entryDate = new Date(entry.date);
        const diff = Math.abs(entryDate - hoverDate);
        if (diff < minDiff) {
          minDiff = diff;
          closestDataPoint = entry;
        }
      });

      if (closestDataPoint) {
        setXPos(closestDataPoint.date);
        setHoveredData(closestDataPoint);
      } else {
        setXPos(null);
        setHoveredData(null);
      }
    }
  }, [hoverPosition, filteredChartData]);

  const traces = selectedDataset.map((dataset, index) => {
    const yAxis = dataset.value === "Covid-19 Deaths" ? 'y' : 'y2';
    return {
      x: filteredChartData.map(entry => entry.date),
      y: filteredChartData.map(entry => entry[dataset.value] || 0),
      type: 'scatter',
      mode: 'lines',
      name: dataset.value,
      line: { color: COLORS[index % COLORS.length] },
      marker: { size: 6 },
      yaxis: yAxis,
      text: filteredChartData.map(entry => `${dataset.value}: ${entry[dataset.value] || 0}`),
      hoverinfo: 'text'
    };
  });

  const layout = {
    title: selectedCounty,
    xaxis: {
      title: 'Date',
      type: 'date',
      tickformat: '%b %Y',
      range: [startInterval, endInterval]  // Ensure this range includes your data
    },
    yaxis: {
      title: 'COVID-19 Deaths',
      side: 'left',
      range: [
        0, 
        160
      ],
    },
    yaxis2: {
      title: 'Other Data',
      side: 'right',
      overlaying: 'y',
      anchor: 'x',
      range: [
        0, 
       30
      ],
    },
    hovermode: 'closest',
    shapes: xPos ? [{
      type: 'line',
      x0: xPos,
      x1: xPos,
      yref: 'paper',
      y0: 0,
      y1: 1,
      line: {
        color: 'green',
        width: 2,
        dash: 'dash',
      },
    }] : [],
    annotations: hoveredData && xPos ? selectedDataset.map((dataset, index) => ({
      x: xPos,
      y: hoveredData[dataset.value],
      xref: 'x',
      yref: dataset.value === "Covid-19 Deaths" ? 'y' : 'y2',
      text: `${hoveredData[dataset.value]}`,
      showarrow: true,
      arrowhead: 2,
      ax: 0,
      ay: -40,
      font: {
        color: COLORS[index % COLORS.length],
      },
      bgcolor: '#fff',
      bordercolor: COLORS[index % COLORS.length],
    })) : [],
    margin: {
      t: 40,
      r: 60,  // Ensure the right margin is sufficient to display yaxis2
      b: 40,
      l: 50,
    },
  };

  const handleMouseMove = (eventData) => {
    if (eventData && eventData.points && eventData.points.length > 0) {
      const date = eventData.points[0].x;
      handleChartHover(date);
    }
  };

  return (
    <div>
      <Plot
        data={traces}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
        config={{ responsive: true }}
        onHover={handleMouseMove}
        onUnhover={handleChartLeave}
      />
    </div>
  );
};

export default OverlayLineChart;
