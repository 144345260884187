// style.js

import styled from "styled-components";

let combinedChartData  = [];
let chartData  = [];

// Function to generate a random color in HEX format
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Generate random colors for each state and create a stateColors object
export const stateColors = (usStatesData) => {
  const colors = {};
  usStatesData.features.forEach((state) => {
    colors[state.properties.NAME] = getRandomColor();
  });
  return colors;
};

// Define the style for county borders
export const countyStyle = {
  fillColor: "transparent",
  weight: 1.5,
  opacity: 1,
  color: "black",
  fillOpacity: 0.7,
};

// Define the style function for state borders
export const getStateStyle = (feature, stateColorsMap) => {
  const stateCode = feature.properties.NAME;
  const stateColor = stateColorsMap[stateCode];
  return {
    fillColor: stateColor,
    weight: 2,
    opacity: 1,
    color: "white",
    fillOpacity: 0.7,
  };
};





// Define the style function for county borders based on COVID-19 death data
export const getCountyColors = (countyData) => { 
  console.log("countyData in style:", countyData);

  if (!countyData || (Array.isArray(countyData) && countyData.length === 0)) {
    return null;
  }
  
if (Array.isArray(countyData)) {
  // If countyData is an array, create a chart for each county
  chartData = countyData.map((county) => ({
    county_name: county.properties.county_name,
    covid_death: county.properties.covid_death === null ? 0 : county.properties.covid_death,
    county_fips_code : county.properties.county_fips_code,
        
  }));
 
} else if (countyData.type === "FeatureCollection") {
  // If countyData is a FeatureCollection, extract data from its features
  chartData = countyData.features.map((feature) => ({
    county_name: feature.properties.county_name,
    covid_death: feature.properties.covid_death === null ? 0 : feature.properties.covid_death,
    county_fips_code : feature.properties.county_fips_code,
  }));
} else {
  // Handle other data formats or throw an error for unsupported formats
  throw new Error("Unsupported countyData format");
}

//console.log("chartData in style:", chartData);

  // Find all features associated with the county
  combinedChartData = chartData.reduce((acc, county) => {
    const {county_fips_code, covid_death } = county;
    const existingData = acc.find((entry) => entry.county_fips_code === county_fips_code);
  
  // Calculate the total COVID-19 deaths for the county
    if (!existingData) {
      // If county_fips_code doesn't exist in the combinedChartData, add a new entry
      acc.push({ county_fips_code, total_covid_death: covid_death });
    } else {
      // If county_fips_code already exists, update the total_covid_death
      existingData.total_covid_death += covid_death;
    }
  
    return acc;
  }, []);


  console.log("combinedChartData from style.js:", combinedChartData);

  // Define the color based on the totalCovidDeath values

  const colors = getColorBasedOnCovidDeath(combinedChartData);
 // console.log("colors from style.js:", colors);
 
return colors;
};


// Helper function to define colors based on COVID-19 death data
export const getColorBasedOnCovidDeath = (combinedChartData, maxDeathCount = 5000) => {
  // Define the color scale parameters for brown gradient
  const endColor = [139, 69, 19]; // Brown
  const startColor = [244, 164, 96];  // Lighter brown

  // Create a color map with county_fips_code as the key
  const colorMap = {};

  // Function to calculate logarithmic scale
  const logScale = (value, max) => {
    const scaleFactor = Math.log10(max + 1);
    return Math.log10(value + 1) / scaleFactor;
  };

  combinedChartData.forEach((data) => {
    const { county_fips_code, total_covid_death } = data;

    // Use logarithmic scale for coloring
    const normalizedDeath = logScale(total_covid_death, maxDeathCount);

    // Interpolate between startColor and endColor based on normalizedDeath
    const interpolatedColor = startColor.map((start, i) => {
      const end = endColor[i];
      return Math.round(start + normalizedDeath * (end - start));
    });

    // Convert RGB values to a hex color code
    const hexColor = interpolatedColor.reduce((acc, value) => {
      const hexValue = value.toString(16).padStart(2, '0');
      return acc + hexValue;
    }, '#');

    // Assign the color to the county_fips_code
    colorMap[county_fips_code] = hexColor;
  });

  return colorMap;
};

// Helper function to generate legend items for logarithmic scale
export const generateLegendForLogScale = (maxDeathCount, numSteps = 5) => {
  const legendItems = [];

  // Function to calculate logarithmic scale
  const logScale = (value, max) => {
    const scaleFactor = Math.log10(max + 1);
    return Math.log10(value + 1) / scaleFactor;
  };

  for (let i = 0; i < numSteps; i++) {
    const stepValue = (i + 1) * (maxDeathCount / numSteps);
    const normalizedStep = logScale(stepValue, maxDeathCount);

    legendItems.push({
      value: stepValue,
      color: getColorBasedOnCovidDeath([{ total_covid_death: stepValue }], maxDeathCount),
    });
  }

  return legendItems;
};




/*
// Generate random colors for each state and create a stateColors object
export const countyColors = (countyData) => {
  const colors = {};
  countyData.features.forEach((county) => {
    colors[county.properties.COUNTY] = getRandomColor();
  });
  return colors;
};

*/

// Define the style function for state borders
export const getCountyStyle = (feature, countyColorsMap) => {
  const countyCode = feature.id;
 // console.log("countyCode from style.js:", countyCode);

  const countyColor = countyColorsMap[countyCode];
  return {
    fillColor: countyColor,
    weight: 2,
    opacity: 1,
    color: "white",
    fillOpacity: 0.7,
  };
};




// Define a styled component for the line chart container
export const LineChartContainer = styled.div`
  padding: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
`;

// Define a styled component for the line chart title
export const LineChartTitle = styled.h2`
  margin-bottom: 10px;
`;

// Define COLORS array that contains different colors for symptomsLineChart.js
export const COLORS = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
  '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  '#aec7e8', '#ffbb78', '#98df8a', '#ff9896', '#c5b0d5',
  '#c49c94', '#f7b6d2', '#c7c7c7', '#dbdb8d', '#9edae5',
  '#393b79', '#637939', '#8c6d31', '#d6616b'
];