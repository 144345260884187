import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { DateTime } from 'luxon';

let chartData = [];
let combinedChartData = [];
let minDate = new Date("2020-02-01");
let maxDate = new Date("2020-04-01");
let xPos;

const yearColorMapping = {
  2020: '#faab63',
  2021: '#b67909',
  2022: '#ef553b',
  2023: '#00cc96',
};

const StateChartComponent = ({ countyData, onLineXPositionChange, hoverPosition, handleChartHover, handleChartLeave, displayLSTM, predictions, onBrushIntervalChange }) => {

  console.log("hoverPosition from stateChart: ", hoverPosition);

  const width = '90%'; // Full width
  const height = '100%'; // Full height

  const [brushInterval, setBrushInterval] = useState(null);
  const [showVerticalLine, setShowVerticalLine] = useState(true);
  const [lineXPosition, setLineXPosition] = useState("2021-01-02");
  const [isPlaying, setIsPlaying] = useState(false);
  const [labelText, setLabelText] = useState("2021-01-02");
  const [showLabel, setShowLabel] = useState(true);
  const [selectedDate, setSelectedDate] = useState("2021-01-02");
  const chartRef = useRef();

  const handleMouseMove = (event) => {
    const mouseX = event.xvals[0]; // The x-coordinate of the mouse position in data coordinates
  
    // Find the closest data point to the mouse position
    let closestPointIndex = 0;
    let minDistance = Infinity;
  
    combinedChartData.forEach((dataPoint, index) => {
      const dataX = new Date(dataPoint.data_as_of).getTime(); // Convert date to timestamp
      const distance = Math.abs(mouseX - dataX);
      if (distance < minDistance) {
        minDistance = distance;
        closestPointIndex = index;
      }
    });
  
    // Call the hover handler with the closest data point's date
    const closestDate = combinedChartData[closestPointIndex].data_as_of;
    handleChartHover(closestDate);
  };
  

  useEffect(() => {
  //  console.log("hoverPosition value:", hoverPosition);
  //  console.log("hoverPosition type:", typeof hoverPosition);
  
    if (hoverPosition) {
      const hoverPositionString = typeof hoverPosition === 'string' ? hoverPosition : new Date(hoverPosition).toISOString();
      setLineXPosition(hoverPositionString);
      setLabelText(hoverPositionString.split('T')[0]);
      setShowVerticalLine(true);
    }
  }, [hoverPosition]);
  
  


  const handleDateChange = (event) => {
    const selectedTimestamp = parseInt(event.target.value);
    if (!isNaN(selectedTimestamp)) {
      const selectedDate = DateTime.fromMillis(selectedTimestamp);
      setSelectedDate(selectedDate);
    } else {
      console.log("Invalid date format:", event.target.value);
    }
  };

  if (countyData && countyData.features.length > 0) {
    minDate = new Date(countyData.features[0].properties.data_as_of).getTime();
    maxDate = new Date(countyData.features[0].properties.data_as_of).getTime();
    countyData.features.forEach((county) => {
      const countyDate = new Date(county.properties.data_as_of).getTime();
      if (countyDate < minDate) {
        minDate = countyDate;
      }
      if (countyDate > maxDate) {
        maxDate = countyDate;
      }
    });
  }

  const handleChartClick = (event) => {
    const xPosition = event.points[0].x;
    setLineXPosition(xPosition);
    onLineXPositionChange(xPosition);
    setBrushInterval([lineXPosition, null]);
    setLabelText(xPosition.split('T')[0]);
    setShowVerticalLine(true);
  };

  const handleMouseLeave = () => {
    if (brushInterval && brushInterval[0] !== null) {
      const newBrushInterval = [...brushInterval];
      const xPosDate = new Date(xPos);

      if (!isNaN(xPosDate.getTime())) {
        const [start, end] = newBrushInterval;
        const brushStart = new Date(start);
        const brushEnd = new Date(end);

        if (isNaN(brushStart.getTime()) || xPosDate < brushStart) {
          newBrushInterval[0] = xPos;
        }
        if (isNaN(brushEnd.getTime()) || xPosDate > brushEnd) {
          newBrushInterval[1] = xPos;
        }

        setBrushInterval(newBrushInterval);
        onBrushIntervalChange(newBrushInterval);
        handleChartLeave();
      } else {
        console.error("Invalid xPos:", xPos);
      }
    }
  };

  const handlePlayPauseClick = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(() => {
        const newTimestamp = new Date(lineXPosition).getTime() + 604800000;
        if (newTimestamp > new Date(maxDate).getTime()) {
          clearInterval(timer);
        } else {
          const newTime = new Date(newTimestamp).toISOString().split('T')[0];
          setLineXPosition(newTime);
          onLineXPositionChange(newTime);
          setLabelText(newTime.split('T')[0]);
        }
      }, 10);
      setShowVerticalLine(true);
    } else {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isPlaying, maxDate, lineXPosition]);

  if (!countyData || countyData.length === 0) {
    return null;
  }

  if (Array.isArray(countyData)) {
    chartData = countyData.map((county) => ({
      county_name: county.properties.county_name,
      covid_death: county.properties.covid_death === null ? 0 : county.properties.covid_death,
      data_as_of: county.properties.data_as_of,
    }));
  } else if (countyData.type === "FeatureCollection") {
    chartData = countyData.features.map((feature) => ({
      county_name: feature.properties.county_name,
      covid_death: feature.properties.covid_death === null ? 0 : feature.properties.covid_death,
      data_as_of: feature.properties.data_as_of,
    }));
  } else {
    throw new Error("Unsupported countyData format");
  }

  combinedChartData = chartData.reduce((acc, county) => {
    const { data_as_of, covid_death } = county;
    const existingData = acc.find((entry) => entry.data_as_of === data_as_of);
    if (!existingData) {
      acc.push({ data_as_of, total_covid_death: covid_death });
    } else {
      existingData.total_covid_death += covid_death;
    }
    return acc;
  }, []);

  combinedChartData.sort((a, b) => {
    return new Date(a.data_as_of) - new Date(b.data_as_of);
  });

  const xMinDate = combinedChartData.length > 0 ? new Date(combinedChartData[0].data_as_of) : new Date();
  const xMaxDate = combinedChartData.length > 0 ? new Date(combinedChartData[combinedChartData.length - 1].data_as_of) : new Date();

  const renderPredictions = () => {
    if (displayLSTM && predictions && predictions.length > 0) {
      return {
        x: predictions.map((pred) => pred.date),
        y: predictions.map((pred) => pred.Predicted_Deaths),
        mode: "lines",
        name: "Predicted Deaths",
        line: { color: "blue" },
      };
    }
    return null;
  };
  
  const data = [];

// Create a single line trace for total COVID deaths
data.push({
  x: combinedChartData.map(data => new Date(data.data_as_of).toISOString()), // Convert to ISO
  y: combinedChartData.map(data => data.total_covid_death),
  mode: 'lines',
  name: 'Total COVID Deaths',
  line: { color: 'black' }, // Set a default color for the line
  showlegend: false, // Hide the legend for the line trace
});

// Iterate through combinedChartData to create year-specific traces
Object.entries(yearColorMapping).forEach(([year, color]) => {
  const yearData = combinedChartData.filter(entry => new Date(entry.data_as_of).getFullYear() === parseInt(year));
  
  if (yearData.length > 0) {
    data.push({
      x: yearData.map(data => new Date(data.data_as_of).toISOString()), // Convert to ISO
      y: yearData.map(data => data.total_covid_death),
      mode: 'lines+markers', // Optionally add markers to the line
      name: ` ${year}`,
      line: { color: color },
     // showlegend: false, // Hide the legend for year traces
    });
  }
});


  const layout = {
  title: {
    text: 'Oklahoma',
    font: {
      family: 'Arial, sans-serif',
      size: 24,
      color: 'black',
      weight: 'bold'
    },
  },
  xaxis: {
    title: 'Date',
    range: [xMinDate.toISOString(), xMaxDate.toISOString()],
    type: 'date',
  },
  yaxis: {
    title: 'Total COVID Deaths',
    range: [0, Math.max(...combinedChartData.map(data => data.total_covid_death)) + 10], // Set y-axis range with some padding
  },
  shapes: [
    showVerticalLine && {
      type: 'line',
      x0: lineXPosition,
      x1: lineXPosition,
      y0: 0,
      y1: 1,
      yref: 'paper',
      line: {
        color: 'red',
        width: 2,
        dash: 'dashdot',
      },
      label: {
        text: labelText,
        xref: 'x',
        yref: 'paper',
        x: lineXPosition,
        y: 1.02,
        showarrow: false,
        font: {
          color: 'red',
        },
      },
    },
    brushInterval && brushInterval[0] && brushInterval[1] && {
      type: 'rect',
      x0: brushInterval[0],
      x1: brushInterval[1],
      y0: 0,
      y1: 1,
      yref: 'paper',
      line: {
        color: 'green',
        width: 1,
        dash: 'dot',
      },
      fillcolor: 'rgba(0, 255, 0, 0.2)',
    },
  ].filter(Boolean),
  margin: {
    t: 100, // Top margin (reduce this value to reduce space between title and chart)
    r: 10, // Right margin
    b: 40, // Bottom margin
    l: 80, // Left margin
  },
};

  const config = {
    responsive: true,
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%', margin: -30, padding: 0, flexDirection: 'row' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 0, padding: -10 }}>
      <Plot
        data={data}
        layout={layout}
        config={config}
        onHover={handleMouseMove} // Use the updated handleMouseMove function
        onUnhover={handleMouseLeave}
        style={{ width: '100%', height: '100%', margin: 0, padding: 0 }} 
      />

      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px', minWidth: '200px', margin: 0 }}>
        <button onClick={handlePlayPauseClick}>{isPlaying ? "Pause" : "Play"}</button>
        <input
          type="date"
          value={lineXPosition.split('T')[0]}
          min={xMinDate.toISOString().split('T')[0]}
          max={xMaxDate.toISOString().split('T')[0]}
          onChange={(e) => setLineXPosition(e.target.value)}
        />
      </div>
    </div>
  );
  
};

export default StateChartComponent;
