import React from 'react';

const LegendComponent = ({ colors }) => {
  return (
    <div className="legend">
      <h3>Markers</h3>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: colors.red }}></span>
        <span>Above Average</span>
      </div>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: colors.orange }}></span>
        <span>Average</span>
      </div>
      <div className="legend-item">
        <span className="legend-color" style={{ backgroundColor: colors.green }}></span>
        <span>Below 10</span>
      </div>
    </div>
  );
};

export default LegendComponent;
