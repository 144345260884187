import React, { useState, useEffect, useRef , useMemo } from "react";
import { MapContainer, TileLayer, GeoJSON, CircleMarker, Popup, FeatureGroup, divIcon, LayerGroup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import usStatesData from "./us_states.json"; // Import the GeoJSON data for US states
import countyBordersData from "./us_counties.json"; // Import the GeoJSON data for county borders
import { countyColors, stateColors, countyStyle, getStateStyle, getCountyStyle, getCountyColors, generateLegendForLogScale } from "./style";
import { LineChart, stateChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { LineChartContainer, LineChartTitle } from "./style"; 
import LineChartComponent from "./LineChart"; 
import StateChartComponent from "./stateChart"; 
import './styles.css';
import LegendComponent from "./LegendComponent";
import { DateTime } from 'luxon';
import L from 'leaflet'; // Import Leaflet
import ReactDOM from 'react-dom'; // Import ReactDOM
import Select from 'react-select';
//import WordCloud from 'react-wordcloud';
import SymptomsLineChart from "./symptomsLineChart"; 
import GridmetLineChart from "./gridmetLineChart";
import WastewaterChartComponent from "./wastewaterLineChart";
import HumanWastewaterChartComponent from "./humanWastewaterLineChart";
import { environment } from './environment';
//import * as pyodide from 'pyodide';
//import lstmModelScript from './modelling/LSTM.py';
import ClimateAttributeWordCloud from "./climateWordCloud";
import OverlayLineChart from "./overlayChart";
import SunburstComponent from "./sunburstChart";
import TreeMapComponent from "./treeMap";
import AggLineChartComponent from "./aggLineChart";




const USMap = () => {
  const mapRef = useRef(null);
  // Wrap the initialization of 'oklahomaCoords' in useMemo to ensure that the oklahomaCoords array is only computed once and won't change on every render unless its dependencies change.
  const oklahomaCoords = useMemo(() => [35.0078, -97.0929], []); // Latitude and Longitude of Oklahoma State
  const zoomLevel = 7;

  // Define stateColorsMap using the stateColors function to generate random colors for each state
  const stateColorsMap = stateColors(usStatesData);

   

  // State to control the visibility of county borders
  const [displayCounties, setDisplayCounties] = useState(true);

  // State to store the selected county from the dropdown menu
  const [selectedCounty, setSelectedCounty] = useState("Tulsa County");

  // State to store the previous selected county
  const [preSelectedCounty, setPreSelectedCounty] = useState("Oklahoma County");

// State to control the visibility of data on Map
  const [displayDataOnMap, setDisplayDataOnMap] = useState(true);

  // State to control the visibility of LSTM model on the COVID-19 chart
  const [displayLSTM, setDisplayLSTM] = useState(true);


  // State to control the visibility of chart on Map
  const [displayChartOnMap, setDisplayChartOnMap] = useState(false);

  // State to store the selected state from the dropdown menu
  const [selectedState, setSelectedState] = useState("Oklahoma");

  // State to store the fetched data from the API
  const [countyData, setCountyData] = useState(null);

   // State to store the fetched data from the API
   const [casesData, setCasesData] = useState(null);

  // State to store the fetched sumptoms data from the API
  const [symptomsData, setSymptomsData] = useState(null);

   // State to store the fetched gridmet data from the API
   const [gridmetData, setGridmetData] = useState(null);

   // State to store the fetched wastewater data from the API
   const [wastewaterData, setWastewaterData] = useState(null);


   // State to store the fetched human wastewater data from the API
   const [humanWastewaterData, setHumanWastewaterData] = useState(null);


   // State to store the fetched waterAnimalPathogens data from the API
   const [waterAnimalPathogensData, setWaterAnimalPathogensData] = useState(null);
   
   // State to store the fetched lstmCountiesPredictedDeaths data from the API
   const [lstmCountiesPredictedDeathsData, setLstmCountiesPredictedDeathsData] = useState(null);

  
  // State to store the selected symptoms names from the dropdown menu
  const [selectedSymptoms, setSelectedSymptoms] = useState([
    {value: 'symptom:Fever', label: 'symptom:Fever'}, 
    {value: 'symptom:Cough', label: 'symptom:Cough'}]);

  // State to store the selected pathogens names from the dropdown menu
  const [selectedPathogens, setSelectedPathogens] = useState([  
    {value: 'SARS CoV2', label: 'SARS CoV2'},
    {value: 'BCV', label: 'BCV'}]);

    // State to store the selected animal pathogens names from the dropdown menu
  const [selectedAnimalPathogens, setSelectedAnimalPathogens] = useState([  
    {value: 'Flu A', label: 'Flu A'},
    {value: 'CDC N2', label: 'CDC N2'}]);

  // State to store the selected gridmet names from the dropdown menu
  const [selectedGridmet, setSelectedGridmet] = useState([
    {value: 'tminc', label: 'Minimum Temperature'},
    {value: 'tmaxc', label: 'Maximum Temperature'}]);

    // State to store the selected dataset names from the dropdown menu
  const [selectedDataset, setSelectedDataset] = useState([
    {value: 'Covid-19 Deaths', label: 'Covid-19 Deaths'},
    {value: 'symptom:Fever', label: 'symptom:Fever'}
  
  ]);

  // State to store the gridmet data of the selected county from the dropdown menu
  const [selectedGridmetData, setselectedGridmetData] = useState(null);


  // State to store the features of the selected county from the dropdown menu
  const [selectedCountyData, setSelectedCountyData] = useState(null);

  // State to store the features of the previously selected county from the dropdown menu
  const [preSelectedCountyData, setPreSelectedCountyData] = useState(null);

  // State to store the predictions of the selected county from the dropdown menu
  const [selectedPredictedCountyData, setSelectedPredictedCountyData] = useState(null);

  // State to store the predictions of the previously selected county from the dropdown menu
  const [preSelectedPredictedCountyData, setPreSelectedPredictedCountyData] = useState(null);

  //State variable to keep track of whether the slider is in "play" mode.
  const [isPlaying, setIsPlaying] = useState(false);

  // State variable to store the data of the clicked county
  const [clickedCountyData, setClickedCountyData] = useState(null);

  // State variable to store selected markers
  const [selectedMarkers, setSelectedMarkers] = useState([]); 

// State variable to store data for the selected markers
const [selectedCounties, setSelectedCounties] = useState([]);

// Define a state variable for lineXPosition from stateChart.js and a handler function to update it:
const [lineXPosition, setLineXPosition] = useState("2021-01-02"); // Initialize with maxDate

const [replaceFirstChart, setReplaceFirstChart] = useState(true);

// a shared state to save the mouse position
const [hoverPosition, setHoverPosition] = useState(null);

// State to store the maxCovid of the selected county to pass it to line chart
const [maxCovid, setMaxCovid] = useState(0);

//State to store the start of the selected interval 
const [startInterval, setStartInterval] = useState("2020-01-02");

//State to store the end of the selected interval 
const [endInterval, setEndInterval] = useState("2023-01-02");

//State to store the selected year and pass it down to the child components
const [selectedYear, setSelectedYear] = useState(null);

//define a callback function for start and end interval
const handleBrushIntervalChange = (interval) => {
  setStartInterval(interval[0]);
  setEndInterval(interval[1]);
};

// Define state variables to store filtered data
const [previousFilteredCovidData, setPreviousFilteredCovidData] = useState(null);
const [currentFilteredCovidData, setCurrentFilteredCovidData] = useState(null);

  // Callback function to update filtered Covid data for the previous county
  const updatePreviousFilteredCovidData = (filteredData) => {
    setPreviousFilteredCovidData(filteredData);
  };

  // Callback function to update filtered Covid data for the current county
  const updateCurrentFilteredCovidData = (filteredData) => {
    setCurrentFilteredCovidData(filteredData);
  };

  
// Define state variables to store filtered data
const [previousFilteredClimateData, setPreviousFilteredClimateData] = useState(null);
const [currentFilteredClimateData, setCurrentFilteredClimateData] = useState(null);

  // Callback function to update filtered Climate data for the previous county
  const updatePreviousFilteredClimateData = (filteredData) => {
    setPreviousFilteredClimateData(filteredData);
  };

  // Callback function to update filtered Climate data for the current county
  const updateCurrentFilteredClimateData = (filteredData) => {
    setCurrentFilteredClimateData(filteredData);
  };

  
// Define state variables to store filtered Symptoms data
const [previousFilteredSymptomsData, setPreviousFilteredSymptomsData] = useState(null);
const [currentFilteredSymptomsData, setCurrentFilteredSymptomsData] = useState(null);

  // Callback function to update filtered Symptoms data for the previous county
  const updatePreviousFilteredSymptomsData = (filteredData) => {
    setPreviousFilteredSymptomsData(filteredData);
  };

  // Callback function to update filtered Symptoms data for the current county
  const updateCurrentFilteredSymptomsData = (filteredData) => {
    setCurrentFilteredSymptomsData(filteredData);
  };

  
// Define state variables to store filtered Wastewater data
const [previousFilteredWastewaterData, setPreviousFilteredWastewaterData] = useState(null);
const [currentFilteredWastewaterData, setCurrentFilteredWastewaterData] = useState(null);

  // Callback function to update filtered Wastewater data for the previous county
  const updatePreviousFilteredWastewaterData = (filteredData) => {
    console.log("setPreviousFilteredWastewaterData(filteredData)", filteredData);
    setPreviousFilteredWastewaterData(filteredData);
  };

  // Callback function to update filtered Wastewater data for the current county
  const updateCurrentFilteredWastewaterData = (filteredData) => {
    console.log("setCurrentFilteredWastewaterData(filteredData)", filteredData);
    setCurrentFilteredWastewaterData(filteredData);
  };


// Define a function to handle mouse changes
  const handleChartHover = (date) => {
    setHoverPosition(date);
  };

  const handleChartLeave = () => {
    setHoverPosition(endInterval);
  };


  // Define a function to handle lineXPosition change
  const handleLineXPositionChange = (newLineXPosition) => {
    setLineXPosition(newLineXPosition);
    setEndInterval(newLineXPosition);
    console.log("New lineXPosition from USMap:", newLineXPosition);
  };




 // Function to filter the countyBordersData based on the selected state and displayCounties value, by comparing the state id
 const filteredCountyBordersData = useMemo(() => {
  console.log("Display Counties:", displayCounties);
  console.log("Selected State:", selectedState);
  if (displayCounties) {
    if (selectedState !== "All" && selectedState) {
      const selectedStateFeature = usStatesData.features.find(
        (feature) => feature.properties.NAME === selectedState
      );

      if (selectedStateFeature) {
       // const selectedStateName = selectedStateFeature.properties.NAME;
        const selectedStateID = selectedStateFeature.id;

        return {
          type: "FeatureCollection",
          features: countyBordersData.features.filter((feature) => {
            const stateID = feature.properties.STATE;
          //  const stateName = feature.properties.STATE;
            return stateID === selectedStateID;
          }),
        };
      }
    } else {
      return countyBordersData; // No need to wrap it in a FeatureCollection if displaying all counties
    }
  } else {
    return null; // Return null when displayCounties is false to hide the county borders
  }
}, [displayCounties, selectedState]);

// state to store LSTM prediction data
const [predictions, setPredictions] = useState([]);



  // Toggle county borders visibility on dropdown menu change
  const handleToggleCountyBorders = (event) => {
    setDisplayCounties((prevValue) => !prevValue);
  };
 
  // Handle selected state change from the dropdown menu
  const handleSelectedStateChange = (event) => {
    const selectedStateValue = event.target.value;
    setDisplayCounties(event.target.value === "false");
    setDisplayDataOnMap(event.target.value === "false");
    setDisplayChartOnMap(event.target.value === "false");
    console.log("Selected state:", selectedStateValue);
    setSelectedState(event.target.value);
  };

  // Handle selected county change from the dropdown menu
  const handleSelectedCountyChange = (event) => {
    const selectedCountyValue = event.target.value;
    setPreSelectedCounty(selectedCounty);
    setSelectedCounty(event.target.value); // Update the selectedCounty state
    console.log("Selected county:", selectedCountyValue);
    console.log("countyData:", countyData);


  if (selectedCountyValue === 'All') {
    // If 'ALL' is selected, store all features from countyData
    setSelectedCountyData(countyData);
    setSelectedPredictedCountyData(lstmCountiesPredictedDeathsData);
  } else {
    // If a specific county is selected, filter features based on the county name
    const newSelectedCountyData = countyData.features.filter((county) => {
      return county.properties.county_name === selectedCountyValue;
    });

    setPreSelectedCountyData(selectedCountyData);

    const newSelectedPredictedCountyData = lstmCountiesPredictedDeathsData.features.filter((county) => {
      return county.properties.county_name === selectedCountyValue;
    });

    setPreSelectedPredictedCountyData(selectedPredictedCountyData);

   const  preMaxCovidDeath = selectedCountyData.reduce((max, obj) => {
    //  console.log('Current covid_death:', obj.properties.covid_death);
      return obj.properties.covid_death > max ? obj.properties.covid_death : max;
    }, 0);
    
    console.log('preMaxCovidDeath:', preMaxCovidDeath);

    

    // Update the selectedCountyData state with the new data
    setSelectedCountyData(newSelectedCountyData);

     // Update the selectedPredictedCountyData state with the new data
     setSelectedPredictedCountyData(newSelectedPredictedCountyData);

  const  maxCovidDeath = newSelectedCountyData.reduce((max, obj) => {
    //  console.log('Current covid_death:', obj.properties.covid_death);
      return obj.properties.covid_death > max ? obj.properties.covid_death : max;
    }, 0);
    
    console.log('maxCovidDeath:', maxCovidDeath);

    const max= preMaxCovidDeath > maxCovidDeath ? preMaxCovidDeath : maxCovidDeath;
    setMaxCovid(max);

    console.log(maxCovid);
    setMaxCovid(preMaxCovidDeath > maxCovidDeath ? preMaxCovidDeath : maxCovidDeath);
    console.log(maxCovid);

  
  }

  

 // console.log("Selected county data:", selectedCountyData);


  };

  // Use useEffect to log the updated selectedCountyData
useEffect(() => {
  console.log("Selected county data:", selectedCountyData);
}, [selectedCountyData]); // This effect will run whenever selectedCountyData changes


  // Update the map view when selected state changes
  useEffect(() => {
    if (mapRef.current) {
      if (selectedState !== "All") {
        const selectedStateFeature = usStatesData.features.find(
          feature => feature.properties.NAME === selectedState
        );
        if (selectedStateFeature) {
        const stateCoordinates = selectedStateFeature.geometry.coordinates[0]; // Use the coordinates of the geometry
        const centroidLat = stateCoordinates.reduce((sum, vertex) => sum + vertex[1], 0) / stateCoordinates.length;
        const centroidLng = stateCoordinates.reduce((sum, vertex) => sum + vertex[0], 0) / stateCoordinates.length;
        const stateCenter = [centroidLat, centroidLng];
        const stateZoom = 7;
        mapRef.current.setView(stateCenter, stateZoom);
      } else {
        // Handle the case where selectedStateFeature is undefined
        console.error(`No feature found for state: ${selectedState}`);
      }
    } else {
      mapRef.current.setView(oklahomaCoords, zoomLevel);
    }
  }
}, [selectedState, oklahomaCoords, zoomLevel, usStatesData]);

  console.log("Filtered County Borders Data:", filteredCountyBordersData);

  //County Data

  // Fetch data from the data API and update the state
  useEffect(() => {
    fetch(`${environment.apiUrl}api/data`) // Flask API is running on the same host and port
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Fetched data:", data);
      setCountyData(data);
      
      const preSelectedCountyData = data.features.filter((county) => {
        return county.properties.county_name === "Oklahoma County";
      });

    const newSelectedCountyData = data.features.filter((county) => {
      return county.properties.county_name === "Tulsa County";
    });

    setPreSelectedCountyData(preSelectedCountyData);
    // Update the selectedCountyData state with the new data
    setSelectedCountyData(newSelectedCountyData);
    
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}, []);


//-----------------------------------------

 // Fetch covid_cases from the covid_cases API and update the state
 useEffect(() => {
  fetch(`${environment.apiUrl}api/covid_cases`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((covid_cases) => {
    console.log("Fetched covid_cases:", covid_cases);
    setCasesData(covid_cases);
  
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
  });
}, []);

//-----------------------------

  // Fetch Symptoms data from the Symptoms API
  useEffect(() => {
    fetch(`${environment.apiUrl}api/symptoms`) // Flask API is running on the same host and port
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((symptoms) => {
      console.log("Fetched Symptoms data:", symptoms);
      setSymptomsData(symptoms);
    })
    .catch((error) => {
      console.error("Error fetching Symptoms data:", error);
    });
}, []);

 // Fetch gridmet data from the gridmet API
 useEffect(() => {
  fetch(`${environment.apiUrl}api/gridmet`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((gridmet) => {
    console.log("Fetched gridmet data:", gridmet);
    setGridmetData(gridmet);
  })
  .catch((error) => {
    console.error("Error fetching gridmet data:", error);
  });
}, []);

// Fetch wastewater data from the wastewater API
useEffect(() => {
  fetch(`${environment.apiUrl}api/wastewater`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((wastewater) => {
    console.log("Fetched wastewater data:", wastewater);
    setWastewaterData(wastewater);
  })
  .catch((error) => {
    console.error("Error fetching wastewater data:", error);
  });
}, []);

// Fetch human_wastewater data from the wastewater API
useEffect(() => {
  fetch(`${environment.apiUrl}api/human_wastewater`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((human_wastewater) => {
    console.log("Fetched human_wastewater data:", human_wastewater);
    setHumanWastewaterData(human_wastewater);
  })
  .catch((error) => {
    console.error("Error fetching human_wastewater data:", error);
  });
}, []);

//waterAnimalPathogens

// Fetch waterAnimalPathogens data from the waterAnimalPathogens API
useEffect(() => {
  fetch(`${environment.apiUrl}api/waterAnimalPathogens`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((waterAnimalPathogens) => {
    console.log("Fetched waterAnimalPathogens data:", waterAnimalPathogens);
    setWaterAnimalPathogensData(waterAnimalPathogens);
  })
  .catch((error) => {
    console.error("Error fetching waterAnimalPathogens data:", error);
  });
}, []);

/*
// Fetch prediction data from predict 
useEffect(() => {
  fetch('http://localhost:3000/predict', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(countyData),
  })
    .then(response => response.json())
    .then(predictions => {
      // Handle the predictions in your JavaScript code
      setPredictions(predictions);
      console.log(predictions);
    })
    .catch((error) => {
      console.error("Error fetching prediction data:", error);
  });
}, [displayLSTM, countyData]);
*/

//lstmCountiesPredictedDeaths

// Fetch lstmCountiesPredictedDeaths data from the lstm_counties_predicted_deaths API
useEffect(() => {
  fetch(`${environment.apiUrl}api/lstm_counties_predicted_deaths`) // Flask API is running on the same host and port
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  })
  .then((lstmCountiesPredictedDeaths) => {
    console.log("Fetched lstmCountiesPredictedDeaths data:", lstmCountiesPredictedDeaths);
    setLstmCountiesPredictedDeathsData(lstmCountiesPredictedDeaths);

    //set the predictions
    const preSelectedPredictedCountyData = lstmCountiesPredictedDeaths.features.filter((county) => {
      return county.properties.county_name === "Oklahoma County";
    });

  const newSelectedPredictedCountyData = lstmCountiesPredictedDeaths.features.filter((county) => {
    return county.properties.county_name === "Tulsa County";
  });

  setPreSelectedPredictedCountyData(preSelectedPredictedCountyData);
  // Update the selectedPredectedCountyData state with the new data
  setSelectedPredictedCountyData(newSelectedPredictedCountyData);
  

  })
  .catch((error) => {
    console.error("Error fetching lstmCountiesPredictedDeaths data:", error);
  });
}, []);

const handleSelectSymptomsChange = (selectedOptions) => {
  setSelectedSymptoms(selectedOptions);
  console.log("SelectedSymptoms:", selectedSymptoms);
 
};

  const symptomOptions = symptomsData && symptomsData.features && countyData
  ? Object.keys(symptomsData.features[0].symptoms).map(symptom => 
    ({ 
      value: symptom, 
      label: symptom 
    }))
  : [];


  const handleSelectDatasetChange = (selectedOptions) => {
    // If more than 2 options are selected, keep only the last two selected options
  if (selectedOptions.length < 3) {

    setSelectedDataset(selectedOptions);
  }
  else {
    alert('You can only select up to two options.');
  }
    
    console.log("SelectedDataset:", selectedDataset);
  };


  
  const handleSelectPathogensChange = (selectedOptions) => {
    setSelectedPathogens(selectedOptions);
    console.log("SelectedPathogens:", selectedPathogens);
    
  };

  const humanPathogensOptions = humanWastewaterData && humanWastewaterData.features
  ? Array.from(
      new Set(humanWastewaterData.features.map(entry => entry.pathogen))
    ).map(pathogen => ({
      value: pathogen,
      label: pathogen,
    }))
  : [];
  
  console.log("humanPathogensOptions: ", humanPathogensOptions);
  const pathogensOptions = wastewaterData && wastewaterData.features
  ? Array.from(
      new Set(wastewaterData.features.map(entry => entry.pathogen))
    ).map(pathogen => ({
      value: pathogen,
      label: pathogen,
    }))
  : [];

  const handleSelectAnimalPathogensChange = (selectedOptions) => {
    setSelectedAnimalPathogens(selectedOptions);
    console.log("SelectedAnimalPathogens:", selectedAnimalPathogens);

  };
  
  const animalPathogensOptions = waterAnimalPathogensData && waterAnimalPathogensData.features
  ? Array.from(
      new Set(waterAnimalPathogensData.features.map(entry => entry.target))
    ).map(target => ({
      value: target,
      label: target,
    }))
  : [];



  
const handleSelectGridmetChange = (selectedOptions) => {
  setSelectedGridmet(selectedOptions);

};

  // Define a mapping between display names and actual column names
  const gridmet_name_mapping = {
      "tminc": "Minimum Temperature",
      "tmeanc": "Mean Temperature",
      "tmaxc": "Maximum Temperature",
      "pr": "Precipitation",
      "rmean": "Mean Relative Humidity",
      "vpd": "Vapor Pressure Deficit",
      "vs": "Wind Speed",
      "net": "Net Effective Temperature (NET)"
  }

  const gridmetOptions = gridmetData && gridmetData.features && countyData
  ? Object.keys(gridmetData.features[0].gridmet).map(gridmet => ({ 
    value: gridmet, 
    label: gridmet_name_mapping[gridmet] || gridmet, // Use the mapped name or the original name if mapping not found 
  }))
  : [];


// State to store the selected datasetOptions
const [datasetOptions, setDatasetOptions] = useState([
  { value: 'Covid-19 deaths', label: 'Covid-19 deaths' },
  { value: 'SARS CoV2', label: 'SARS CoV2' }, // Example default value
  { value: 'tminc', label: 'Minimum Temperature' }, // Example default value
]);

// Use useEffect to update datasetOptions
useEffect(() => {
  // Combine all selected options into one array
  const allSelectedOptions = [...selectedSymptoms, ...selectedPathogens, ...selectedGridmet];

  // Object to store unique values
  const uniqueValues = {};

  // Iterate over each selected option and extract unique values
  allSelectedOptions.forEach(option => {
    uniqueValues[option.value] = option.label;
  });

  // Convert uniqueValues object keys into datasetOptions array
  const newDatasetOptions = Object.keys(uniqueValues).map(value => ({
    value: value,
    label: uniqueValues[value]
  }));

  // Update the datasetOptions state with the new array
  setDatasetOptions([
    { value: 'Covid-19 deaths', label: 'Covid-19 deaths' },
    ...newDatasetOptions
  ]);

  console.log("datasetOptions:", datasetOptions);
}, [selectedPathogens, selectedGridmet, selectedSymptoms]); // This effect will run whenever these change


  //function to handle the checkbox change for displaying LSTM model:
const handleToggleLSTM = (event) => {
  // Toggle the state when the checkbox is clicked
  setDisplayLSTM((prevValue) => !prevValue);
  console.log("prediction Data", predictions);

};

//function to handle the dropdown menu change for displaying data on the map:
const handleToggleDataOnMap = (event) => {
   // Toggle the state when the checkbox is clicked
   setDisplayDataOnMap((prevValue) => !prevValue);
  setDisplayCounties((prevValue) => !prevValue);
};

//function to handle the dropdown menu change for displaying chart on the map:
const handleToggleChartOnMap = (event) => {
  setDisplayChartOnMap(event.target.value === "true");
};


// The marker's click event handler
const handleMarkerClick = (clickedCountyName) => {
    // If a specific county is selected, filter features based on the county name
    const newSelectedCountyData = countyData.features.filter((county) => {
     return county.properties.county_name === clickedCountyName;
    });

    const newSelectedPredictedCountyData = lstmCountiesPredictedDeathsData.features.filter((county) => {
      return county.properties.county_name === clickedCountyName;
    });

      
    // Update the selectedCountyData state with the new data
    setPreSelectedCounty(selectedCounty);
    setPreSelectedCountyData(selectedCountyData);

    // Update the preSelectedPredictedCountyData state with the new data
    setPreSelectedPredictedCountyData(selectedPredictedCountyData);

  
  const  preMaxCovidDeath = selectedCountyData.reduce((max, obj) => {
      //  console.log('Current covid_death:', obj.properties.covid_death);
        return obj.properties.covid_death > max ? obj.properties.covid_death : max;
      }, 0);
      
      console.log('preMaxCovidDeath:', preMaxCovidDeath);

    setSelectedCountyData(newSelectedCountyData);

    // Update the selectedPredectedCountyData state with the new data
  setSelectedPredictedCountyData(newSelectedPredictedCountyData);

  const  maxCovidDeath = newSelectedCountyData.reduce((max, obj) => {
      //  console.log('Current covid_death:', obj.properties.covid_death);
        return obj.properties.covid_death > max ? obj.properties.covid_death : max;
      }, 0);
      
      console.log('maxCovidDeath:', maxCovidDeath);
  
      const max= preMaxCovidDeath > maxCovidDeath ? preMaxCovidDeath : maxCovidDeath;
      setMaxCovid(max);
  
      console.log('maxCovid:', max);
      setMaxCovid(preMaxCovidDeath > maxCovidDeath ? preMaxCovidDeath : maxCovidDeath);

    setSelectedCounty(clickedCountyName);

    console.log("selcetedCounty from marker:", selectedCounty);
    console.log("preSelcetedCounty from marker:", preSelectedCounty);

    
    if (selectedCounties.length < 1) {
      setSelectedCounties((prevSelectedCounties) => [
        ...prevSelectedCounties,
        newSelectedCountyData,
      ]);
    } else {
      if (replaceFirstChart) {
      // Replace the first selected county with the new one
      setSelectedCounties((prevSelectedCounties) => [
        prevSelectedCounties[1],
        newSelectedCountyData,
      ]);
    } else {
      // Replace the second selected county with the new one
      setSelectedCounties((prevSelectedCounties) => [
        prevSelectedCounties[0],
        newSelectedCountyData,
      ]);
    }
 
    setReplaceFirstChart(prev => !prev); // Toggle the boolean value for the next click
};
console.log("selectedCounties:", selectedCounties);
};


  // To calculate the averageCovidDeath for all counties
  const calculateAverageCovidDeath = (countyData) => {
    let totalDeaths = 0;
    let totalCounties = 0;
  
    countyData.features.forEach((county) => {
      const { properties } = county;
      const { covid_death } = properties;
  
      if (covid_death !== null) {
        totalDeaths += covid_death;
        totalCounties++;
      }
    });
  
    return totalDeaths / totalCounties;
  };

  
// Function to handle county click event handler
const handleCountyBorderClick = (event, feature) => {
  // Check if the event has the `target` property and if `target.feature` exists

    const countyName = feature.NAME; // Make sure to use the correct property name
  //  handleMarkerClick(countyName);
    console.log('Clicked on county border:', countyName);
  
};

  
 //to show marks on the map for each county 
  const renderCountyMarkers = () => {
    console.log("County Data from renderCountyMarkers:", countyData);
    if (!countyData || !countyData.features) {
      console.log("No countyData from the database!");
      return null;
    }
      
  //  console.log("selectedDate:", selectedDate);
  console.log("LineXPosition from renderCountyMarkers:", lineXPosition);

  //  Filter the county markers based on the selected date
   const filteredMarkers = countyData.features
   .filter((countys) => {
   const countyDate = new Date(countys.properties.data_as_of);
        const selectedDateOnly = new Date(lineXPosition);
        return (
          countyDate.getUTCFullYear() === selectedDateOnly.getUTCFullYear() &&
          countyDate.getUTCMonth() === selectedDateOnly.getUTCMonth() &&
          countyDate.getUTCDate() === selectedDateOnly.getUTCDate()
        );
      })
   .map((countys) => {
    const { properties } = countys;
    const { state, county_name, county_fips_code, covid_death, data_as_of } = properties;
 // console.log("County name from database:", county_name);

      
     // Look up coordinates for the county name
    const countyCoordinates = filteredCountyBordersData.features.find(
      // the county name from the CDC datasets contains "County" word
     (feature) => feature.properties.NAME+ " County" === county_name
    
      );



  //  console.log("countyCoordinates =", countyCoordinates);
    if (!countyCoordinates) {
      //console.log("No countyCoordinates!");
      return null; // Skip rendering if coordinates are not available  /
    }

   const polygons = countyCoordinates.geometry.coordinates; // Array of polygons

    const markers = polygons.map((polygon, index) => {
      // Check if any of the polygon's vertices have NaN values
  const hasInvalidCoordinates = polygon.some(vertex => isNaN(vertex[0]) || isNaN(vertex[1]));

  if (hasInvalidCoordinates) {
    console.log("Skip rendering for polygons with invalid coordinates!", county_name);
    return null; // Skip rendering for polygons with invalid coordinates
  }

      // Calculate the centroid of the polygon for marker placement
      const centroidLat = polygon.reduce((sum, vertex) => sum + vertex[1], 0) / polygon.length;
      const centroidLng = polygon.reduce((sum, vertex) => sum + vertex[0], 0) / polygon.length;

      // Calculate the radius and color based on covid_death data
      const radius = Math.sqrt(covid_death);
     // const color = covid_death > 100 ? "red" : "orange";

     const averageCovidDeath = calculateAverageCovidDeath(countyData);
    let color;
    if (covid_death >= averageCovidDeath) {
      color = "red";
    } else if (covid_death <= averageCovidDeath && covid_death > 10) {
      color = "orange";
    } else {
      color = "green";
    }

    
      return (
        <CircleMarker
          key={`${county_name}-${state}-${index}`} // Include index to ensure uniqueness
          center={[centroidLat, centroidLng]}
          radius={radius}
          fillColor={color}
          fillOpacity={0.7}
          stroke={false}
          eventHandlers={{
            click: () => handleMarkerClick(county_name), // Pass the county data when the marker is clicked
          }}
        >
          <Popup>
            <strong>County: </strong> {county_name} <br />
            <strong>State: </strong> {selectedState} <br />
            <strong>COVID-19 Death: </strong> {covid_death} <br />
            <strong>Date: </strong> {data_as_of} <br />
          </Popup>
        </CircleMarker>
      );
    });
  

    return (
      <div key={`${county_name}-${state}`}>
        {markers}
      </div>
    );
  }, []);
  return filteredMarkers;  
};


   // Define the colors for the legend
   const legendColors = {
    red: "red",
    orange: "orange",
    green: "green",
  };

  // Define countyColorsMap using the countyColors function to generate random colors for each county
   const countyColorsMap = getCountyColors(countyData);

   //   console.log("countyColorsMap in USMap:", countyColorsMap);

   
    const maxDeathCount = 5000;
    const legendItems = generateLegendForLogScale(maxDeathCount);
    console.log("legendItems in USMap:", legendItems);




  return (
    
 <div>
     {/* Ribbon */}
      <div className="ribbon" style={{ position: 'relative', top: '0', backgroundColor: 'darkred', color: '#fff', height: '5vh', width: '100%', display: 'flex', alignItems: 'center' }}>
        <div className="left-section" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
          <img src='OULogo.png' style={{ width: '300px', height: 'auto' }} alt="OU Logo" />
        </div>
        <div className="content" style={{ display: 'flex', alignItems: 'center', flexGrow: '1', fontSize: 'revert', margin: '15px', marginRight: '143px' }}>
          <h1 style={{ textAlign: 'center' }}>Predictive Intelligence for Pandemic Prevention (PIPP)</h1>
        </div>
      </div> 

 <div className="grid-container"> 

 <div className="grid-item-full">
  
  <div className="dropdown-container">
      {/* Dropdown menu for selecting a specific state */}
      <div className="dropdown-menu">
        <label className="dropdown-label" htmlFor="selectState">Select a State:</label>
        <select className="dropdown-select" id="selectState" value={selectedState} onChange={handleSelectedStateChange}>
        <option key="All">All</option>
        {usStatesData.features.map(feature => (
          <option key={feature.properties.STATE} value={feature.properties.STATE}>
            {feature.properties.NAME}
          </option>
        ))}
        </select>
      </div>

      {/* Dropdown menu for selecting a specific county for the selected state */}
      <div className="dropdown-menu">
        <label className="dropdown-label" htmlFor="selectCounty">Select a County:</label>
        {filteredCountyBordersData && (
        <select className="dropdown-select" id="selectCounty" value={selectedCounty|| "All"} onChange={handleSelectedCountyChange}>
          <option key="All">All</option>
          {filteredCountyBordersData.features.map(feature => (
          <option key={feature.properties.NAME} value={feature.properties.NAME+" County"}>
            {feature.properties.NAME+" County"}
          </option>
            ))}
        </select>
        )}
      </div>

      {/* Dropdown menu for toggling county borders 
      <div className="dropdown-menu">
        <label className="dropdown-label" htmlFor="toggleCounties">Display County Borders:</label>
          <select className="dropdown-select" id="toggleCounties" value={displayCounties.toString()} onChange={handleToggleCountyBorders}>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
      </div>
      */}


      {/* Dropdown menu for toggling county borders */}
      <div className="dropdown-menu">
        <input
          type="checkbox"
          id="toggleData"
          checked={displayCounties}
          onChange={handleToggleCountyBorders}
        />
        <label htmlFor="toggleData">Display County Borders</label>
      </div>


      {/* Dropdown menu for toggling the data on Map
      <div className="dropdown-menu">
        <label className="dropdown-label" htmlFor="toggleData">Display Data on Map:</label>
        {selectedState==="Oklahoma" && (
        <select className="dropdown-select" id="toggleData" value={displayDataOnMap.toString()} onChange={handleToggleDataOnMap}>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      )}
      </div>
      */}

      {/* Checkbox for toggling the data on Map*/}
      <div className="dropdown-menu">
        <input
          type="checkbox"
          id="toggleData"
          checked={displayDataOnMap}
          onChange={handleToggleDataOnMap}
        />
        <label htmlFor="toggleData">Display Data on Map</label>
      </div>



      {/* Dropdown menu for toggling the chart on Map
      <div className="dropdown-menu">
        <label className="dropdown-label" htmlFor="toggleChart">Display Chart on Map:</label>
        <select className="dropdown-select" id="toggleChart" value={displayChartOnMap.toString()} onChange={handleToggleChartOnMap}>
          <option value="false">No</option>
          <option value="true">Yes</option>
        </select>
      </div>
      */}
      
    </div>
        
      </div>

      <div className="grid-item" style={{ display: 'flex' }}>
      <div className="line-chart-container" style={{ display: 'flex'}}>
        {/* MapContainer */}
        <MapContainer ref={mapRef} center={oklahomaCoords} zoom={zoomLevel}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* GeoJSON layer for state borders */}
            {selectedState === "All" && <GeoJSON data={usStatesData} style={(feature) => getStateStyle(feature, stateColorsMap)} />}
          {/* Conditional GeoJSON layer for county borders */}
            {displayCounties && <GeoJSON data={filteredCountyBordersData} style={countyStyle} />}
          {/* Conditional GeoJSON layer for county data */}
            {displayDataOnMap && countyData && 
          <GeoJSON 
            data={countyBordersData} 
            style={(feature) => getCountyStyle(feature, countyColorsMap)}
            onEachFeature={(feature, layer) => {
            // Attach click event to each county border polygon
              layer.on({
                click: (event) => handleCountyBorderClick(event, feature),
              });
            }}
          />}
          {/* Render the markers on map */}
            {displayDataOnMap && filteredCountyBordersData && renderCountyMarkers()}
          {/* Render the charts on map */}
           {displayChartOnMap}

        {/* Legend */}
          <LegendComponent colors={legendColors} />

        </MapContainer>
     </div>
     </div>
     <div className="info-grid-container">
      <div className="info-grid-item">
        <div className="info-chart-container">
          {casesData && 
          <SunburstComponent 
            casesData={casesData} 
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear} // Pass the selected year to the sunburst
          />}
        </div>
      </div>
       {/*
      <div className="info-grid-item">
        <div className="info-chart-container">
          {casesData && 
          <TreeMapComponent 
            casesData={casesData} 
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear} // Pass the selected year to the treemap
          />}
        </div>
      </div>
       */}
      <div className="info-grid-item">
        <div className="info-chart-container">
          {casesData && 
          <AggLineChartComponent 
            casesData={casesData} 
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear} // Pass the selected year to the line chart
            handleChartHover={handleChartHover}
            handleChartLeave={handleChartLeave}
          />}
        </div>
      </div>
    </div>

      <div className="state-grid-item">
      <div className="state-chart-container" >
      {selectedState === "Oklahoma" && 
          <StateChartComponent
            countyData={countyData}
            onLineXPositionChange={handleLineXPositionChange}
            hoverPosition={hoverPosition}
            handleChartHover={handleChartHover}
            handleChartLeave={handleChartLeave}
            displayLSTM={displayLSTM}
            predictions={countyData}
            onBrushIntervalChange={handleBrushIntervalChange}
          />}

      </div>
      </div>
      
      <div className="grid-item grid-item-full">
      <h2>Combined datasets</h2>
      <div className="overlay-chart-container"  style={{ display: 'flex' }}>
          {/* combined chart for the current county */}
          { countyData && preSelectedCounty && gridmetData && symptomsData && humanWastewaterData &&
            <OverlayLineChart
              selectedDataset={selectedDataset}
              filteredCovidData={previousFilteredCovidData}
              filteredClimateData={previousFilteredClimateData}
              filteredSymptomsData={previousFilteredSymptomsData}
              filteredWastewaterData={previousFilteredWastewaterData}
              selectedCounty={preSelectedCounty} 
              hoverPosition={hoverPosition}
              handleChartHover={handleChartHover}
              handleChartLeave={handleChartLeave}
              startInterval={startInterval}
              endInterval={endInterval} 
            />
          }

          {/* combined chart for the previous county */ }
          {countyData && preSelectedCounty && gridmetData && symptomsData && humanWastewaterData &&
            <OverlayLineChart 
              selectedDataset={selectedDataset}
              filteredCovidData={currentFilteredCovidData}
              filteredClimateData={currentFilteredClimateData}
              filteredSymptomsData={currentFilteredSymptomsData}
              filteredWastewaterData={currentFilteredWastewaterData}
              selectedCounty={selectedCounty}
              hoverPosition={hoverPosition}
              handleChartHover={handleChartHover}
              handleChartLeave={handleChartLeave}
              startInterval={startInterval}
              endInterval={endInterval}
            />
          }

         
      </div>
      

      <div className="overlay-menu-container">
          <Select
            isMulti
            value={selectedDataset}
            onChange={handleSelectDatasetChange}
            options={datasetOptions}
          />
      </div>   
      </div> 

      <div className="grid-item" style={{ width:"100%"}}>
      <h2>COVID-19 Deaths</h2>
      <div className="line-chart-container" style={{ display: 'flex'}}>
        {/* Covid-19 LineChart Container */}

         {/* Covid-19 deaths chart for the previous county */}
        {countyData && preSelectedCountyData && 
          <LineChartComponent
            countyData={preSelectedCountyData}
            selectedDate={lineXPosition}
            selectedCounty={preSelectedCounty}
            predictionData={preSelectedPredictedCountyData}
            displayLSTM={displayLSTM}
            hoverPosition={hoverPosition}
            handleChartHover={handleChartHover}
            handleChartLeave={handleChartLeave}
            startInterval={startInterval}
            endInterval={endInterval}
            onUpdateFilteredData={updatePreviousFilteredCovidData}
          />}

          {/* Covid-19 deaths chart for the current county */}
          {countyData &&  selectedCountyData &&
            <LineChartComponent
              countyData={selectedCountyData}
              selectedDate={lineXPosition}
              selectedCounty={selectedCounty}
              predictionData={selectedPredictedCountyData}
              displayLSTM={displayLSTM}
              hoverPosition={hoverPosition}
              handleChartHover={handleChartHover}
              handleChartLeave={handleChartLeave}
              startInterval={startInterval}
              endInterval={endInterval}
              onUpdateFilteredData={updateCurrentFilteredCovidData}
            />}
       
      </div> 

      {/* Checkbox for toggling LSTM model*/}
      <div className="dropdown-menu">
        <input
          type="checkbox"
          id="toggleData"
          checked={displayLSTM}
          onChange={handleToggleLSTM}
        />
        <label htmlFor="toggleData">LSTM prediction model</label>
      </div>

      </div> 
      
   
      <div className="grid-item">
      <h2>Climate (gridMET) </h2>
          <div className="gridmet-chart-container"  style={{ display: 'flex'}}>
              {/* gridmet chart for the current county */}
                {gridmetData && selectedGridmet && preSelectedCounty &&
                  <GridmetLineChart 
                    gridmetData={gridmetData} 
                    selectedCounty={preSelectedCounty} 
                    selectedGridmet={selectedGridmet}
                    hoverPosition={hoverPosition}
                    handleChartHover={handleChartHover}
                    handleChartLeave={handleChartLeave}
                    startInterval={startInterval}
                    endInterval={endInterval}
                    onUpdateFilteredData={updatePreviousFilteredClimateData}
                  />
                }
              {/* gridmet chart for the previous county */}
                {gridmetData && selectedGridmet && selectedCounty &&
                  <GridmetLineChart 
                    gridmetData={gridmetData} 
                    selectedCounty={selectedCounty} 
                    selectedGridmet={selectedGridmet}
                    hoverPosition={hoverPosition}
                    handleChartHover={handleChartHover}
                    handleChartLeave={handleChartLeave}
                    startInterval={startInterval}
                    endInterval={endInterval}
                    onUpdateFilteredData={updateCurrentFilteredClimateData}
                  />
                }
            </div>

            <div className="gridmet-menu-container">
                <Select
                  isMulti
                  value={selectedGridmet}
                  onChange={handleSelectGridmetChange}
                  options={gridmetOptions}
                />
            </div>  
      </div>  

      
      <div className="grid-item">
      <h2>Google Symptoms</h2>
      <div className="symptoms-chart-container"  style={{ display: 'flex' }}>
          {/* Symptoms chart for the current county */}
          { symptomsData && selectedSymptoms && preSelectedCounty &&
            <SymptomsLineChart 
              symptomsData={symptomsData} 
              selectedCounty={preSelectedCounty} 
              selectedSymptoms={selectedSymptoms}
              hoverPosition={hoverPosition}
              handleChartHover={handleChartHover}
              handleChartLeave={handleChartLeave}
              startInterval={startInterval}
              endInterval={endInterval}
              onUpdateFilteredData={updatePreviousFilteredSymptomsData}
            />
          }

          {/* Symptoms chart for the previous county */}
          {symptomsData && selectedSymptoms && selectedCounty &&
            <SymptomsLineChart 
              symptomsData={symptomsData} 
              selectedCounty={selectedCounty} 
              selectedSymptoms={selectedSymptoms}
              hoverPosition={hoverPosition}
              handleChartHover={handleChartHover}
              handleChartLeave={handleChartLeave}
              startInterval={startInterval}
              endInterval={endInterval}
              onUpdateFilteredData={updateCurrentFilteredSymptomsData}
            />
          }
      </div>
      

      <div className="symptoms-menu-container">
          <Select
            isMulti
            value={selectedSymptoms}
            onChange={handleSelectSymptomsChange}
            options={symptomOptions}
          />
      </div>   
      </div> 

{/* *********** This is to display the wastewater data as whole state *************
      <div className="grid-item">
        <h2>Wastewater data</h2>
          <div className="wastewater-chart-container"  style={{ display: 'flex' }}>
              {selectedState === "Oklahoma" && wastewaterData && selectedPathogens && countyData &&
                    <WastewaterChartComponent
                      wastewaterData={wastewaterData}
                      selectedPathogens={selectedPathogens}
                      hoverPosition={hoverPosition}
                      handleChartHover={handleChartHover}
                      handleChartLeave={handleChartLeave}
                      title={"Human Pathogens"}
                    />}
                
              {selectedState === "Oklahoma" && waterAnimalPathogensData && selectedAnimalPathogens && countyData &&
                    <WastewaterChartComponent
                      wastewaterData={waterAnimalPathogensData}
                      selectedPathogens={selectedAnimalPathogens}
                      hoverPosition={hoverPosition}
                      handleChartHover={handleChartHover}
                      handleChartLeave={handleChartLeave}
                      title={"Animal Pathogens"}
                    />}
          </div> 

          <div className="wastewater-menu-container"  style={{ display: 'flex' }}>
                <Select
                      isMulti
                      value={selectedPathogens}
                      onChange={handleSelectPathogensChange}
                      options={pathogensOptions}
                    />

                    <Select
                      isMulti
                      value={selectedAnimalPathogens}
                      onChange={handleSelectAnimalPathogensChange}
                      options={animalPathogensOptions}
                    />
          </div> 
          
           */}

    {  // ********** This is to display the wastewater data as county level ************* 
    }
        <div className="grid-item">
                <h2>Human Wastewater data</h2>
                  <div className="wastewater-chart-container"  style={{ display: 'flex' }}>
                      {humanWastewaterData && selectedPathogens && preSelectedCounty &&
                            <HumanWastewaterChartComponent
                              wastewaterData={humanWastewaterData}
                              selectedCounty={preSelectedCounty} 
                              selectedPathogens={selectedPathogens}
                              hoverPosition={hoverPosition}
                              handleChartHover={handleChartHover}
                              handleChartLeave={handleChartLeave}
                              startInterval={startInterval}
                              endInterval={endInterval}
                              onUpdateFilteredData={updatePreviousFilteredWastewaterData}
                              filteredWastewaterDataProp={previousFilteredWastewaterData}
                            />}
                        
                      {humanWastewaterData && countyData &&
                            <HumanWastewaterChartComponent
                              wastewaterData={humanWastewaterData}
                              selectedCounty={selectedCounty}
                              selectedPathogens={selectedPathogens}
                              hoverPosition={hoverPosition}
                              handleChartHover={handleChartHover}
                              handleChartLeave={handleChartLeave}
                              startInterval={startInterval}
                              endInterval={endInterval}
                              onUpdateFilteredData={updateCurrentFilteredWastewaterData}
                              filteredWastewaterDataProp={currentFilteredWastewaterData}
                            />}
                  </div> 

                  <div className="wastewater-menu-container"  style={{ display: 'flex' }}>
                        <Select
                              isMulti
                              value={selectedPathogens}
                              onChange={handleSelectPathogensChange}
                              options={humanPathogensOptions}
                            />
                  </div>
                      
      </div>

    </div> 
    </div>
    
    
  );
};

export default USMap;