import React, { useRef, useEffect, useState } from 'react';
import Plotly from 'plotly.js-dist';

const SunburstComponent = ({ casesData, setSelectedYear, selectedYear }) => {
  const plotRef = useRef(null);
  const [plotData, setPlotData] = useState(null);

  // Function to extract year from date string
  const extractYear = (dateString) => new Date(dateString).getFullYear();

  // Function to extract month from date string (1-based index)
  const extractMonth = (dateString) => new Date(dateString).getMonth() + 1;

  const getSunburstData = (data) => {
    const labels = [];
    const parents = [];
    const values = [];

    const totals = {};
    const monthOrder = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

    data.features.forEach((entry) => {
      const year = extractYear(entry.start_week);
      const month = extractMonth(entry.start_week);
      const value = entry.new_cases;

      if (!totals[year]) {
        totals[year] = { name: year.toString(), children: monthOrder.map(month => ({ name: month, size: 0 })) };
        labels.push(year.toString());
        parents.push('');
        values.push(0);
      }

      let monthNode = totals[year].children.find((child) => child.name === month.toString());
      if (!monthNode) {
        monthNode = { name: month.toString(), size: 0 };
        totals[year].children.push(monthNode);
      }

      monthNode.size += value;
    });

    const sortedLabels = [];
    const sortedParents = [];
    const sortedValues = [];

    Object.keys(totals).forEach((year) => {
      sortedLabels.push(year);
      sortedParents.push('');
      sortedValues.push(totals[year].children.reduce((acc, child) => acc + child.size, 0));

      totals[year].children
        .sort((a, b) => parseInt(a.name) - parseInt(b.name))
        .forEach((monthNode) => {
          const monthLabel = `${year}-${monthNode.name}`;
          sortedLabels.push(monthLabel);
          sortedParents.push(year);
          sortedValues.push(monthNode.size);
        });
    });

    return { labels: sortedLabels, parents: sortedParents, values: sortedValues };
  };

  useEffect(() => {
    const sunburstData = getSunburstData(casesData);
    setPlotData({
      labels: sunburstData.labels,
      parents: sunburstData.parents,
      values: sunburstData.values,
    });
  }, [casesData]);

  useEffect(() => {
    if (plotRef.current && plotData) {
      let filteredPlotData = plotData;
      if (selectedYear) {
        filteredPlotData = {
          labels: plotData.labels.filter((l) => l.startsWith(`${selectedYear}-`) || l === selectedYear),
          parents: plotData.parents.filter((p, idx) => {
            const label = plotData.labels[idx];
            return label.startsWith(`${selectedYear}-`) || label === selectedYear;
          }),
          values: plotData.values.filter((_, idx) => {
            const label = plotData.labels[idx];
            return label.startsWith(`${selectedYear}-`) || label === selectedYear;
          }),
        };
      }

      const data = [{
        type: 'sunburst',
        labels: filteredPlotData.labels,
        parents: filteredPlotData.parents,
        values: filteredPlotData.values,
        branchvalues: 'total',
        outsidetextfont: { size: 20, color: '#377eb8' },
        leaf: { opacity: 0.4 },
        marker: { line: { width: 2 } },
        hoverinfo: 'label+value+percent root',
      }];

      const layout = {
        margin: { l: 0, r: 0, b: 0, t: 0 },
        sunburstcolorway: ['#ef553b', '#b67909', '#faab63', '#00cc96'],
        extendsunburstcolorway: true,
        width: 300,
        height: 300,
      };

      Plotly.newPlot(plotRef.current, data, layout);

      const handleClick = (eventData) => {
        if (eventData.points.length > 0) {
          const point = eventData.points[0];
          const label = point.label;
          const depth = point.depth;

          console.log('Clicked:', { label, depth });
          setSelectedYear(label);

          if (depth === 1) {
            console.log('Year clicked:', label);
            setSelectedYear(label);
          } else if (depth === 2) {
            console.log('Month clicked:', label);
            setSelectedYear(null);
          }
        }
      };

      plotRef.current.on('plotly_click', handleClick);

      return () => {
        if (plotRef.current) {
          plotRef.current.removeAllListeners('plotly_click');
        }
      };
    }
  }, [plotData, casesData, selectedYear]);

  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold', fontSize: '24px' }}>
        Oklahoma
      </div>
      <div id="plotly-div" ref={plotRef} />
    </div>
  );
};

export default SunburstComponent;
