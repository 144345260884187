import React, { useState, useEffect, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { COLORS } from './style';

const calculateFilteredData = (chartData, startInterval, endInterval) => {
  return chartData.filter(entry => {
    const entryDate = new Date(entry.date).getTime();
    return entryDate >= startInterval.getTime() && entryDate <= endInterval.getTime();
  });
};

const SymptomsLineChart = ({ symptomsData, selectedCounty, selectedSymptoms, hoverPosition, handleChartHover, handleChartLeave, startInterval, endInterval, onUpdateFilteredData }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [xPos, setXPos] = useState(null);

  // Ensure startInterval and endInterval are Date objects
  const startDate = useMemo(() => new Date(startInterval), [startInterval]);
  const endDate = useMemo(() => new Date(endInterval), [endInterval]);

  // Filter symptoms data based on selected county
  const selectedCountyData = useMemo(() => {
    return symptomsData.features.filter(
      (county) => ((county.sub_region_2 === selectedCounty + " County") || (county.sub_region_2 === selectedCounty)) && (county.sub_region_2 !== null)
    );
  }, [symptomsData, selectedCounty]);

  // Prepare data for the chart
  const symptomsChartData = useMemo(() => {
    return selectedSymptoms.flatMap(selectedSymptom => {
      const symptomName = selectedSymptom?.value;
      if (symptomName) {
        return selectedCountyData.map(entry => ({
          name: symptomName,
          date: entry.date,
          value: entry.symptoms?.[symptomName] || 0 // Default to 0 if symptom is not present
        }));
      }
      return [];
    });
  }, [selectedCountyData, selectedSymptoms]);

  // Group the data by symptomName and create a series for each symptom
  const chartData = useMemo(() => {
    let newChartData = Object.values(
      symptomsChartData.reduce((acc, symptomData) => {
        const { name, value, date } = symptomData;
        if (!acc[name]) {
          acc[name] = { name, data: [] };
        }
        acc[name].data.push({ value: value === null ? 0 : value, date });
        return acc;
      }, {})
    );

    newChartData.forEach(series => {
      series.data.sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    const combinedData = {};
    newChartData.forEach(series => {
      series.data.forEach(dataPoint => {
        const date = dataPoint.date;
        if (!combinedData[date]) {
          combinedData[date] = { date, data: {} };
        }
        combinedData[date].data[series.name] = dataPoint.value;
      });
    });

    return Object.values(combinedData);
  }, [symptomsChartData]);

  // Update filtered data based on chartData
  useEffect(() => {
    const filtered = calculateFilteredData(chartData, startDate, endDate);
    setFilteredData(filtered);
    onUpdateFilteredData(filtered);
  }, [startDate, endDate, chartData, onUpdateFilteredData]);

  // Calculate hover position
  useEffect(() => {
    if (hoverPosition && filteredData.length > 0) {
      const hoverDate = new Date(hoverPosition);
      let closestDataPoint = null;
      let minDiff = Infinity;

      filteredData.forEach(entry => {
        const entryDate = new Date(entry.date);
        const diff = Math.abs(entryDate - hoverDate);
        if (diff < minDiff) {
          minDiff = diff;
          closestDataPoint = entry;
        }
      });

      if (closestDataPoint) {
        setXPos(closestDataPoint.date);
      } else {
        setXPos(null);
      }
    }
  }, [hoverPosition, filteredData]);

  // Prepare the data for Plotly
  const data = selectedSymptoms.map((symptom, index) => {
    const symptomName = symptom.value;
    return {
      x: filteredData.map(entry => entry.date),
      y: filteredData.map(entry => entry.data?.[symptomName] || 0), // Default to 0 if data is not present
      type: 'scatter',
      mode: 'lines',
      name: symptom.label,
      line: { color: COLORS[index % COLORS.length] },
    };
  });

  // Add vertical line and annotation if hoverPosition is available
  const layout = {
    title: {
      text: selectedCounty,
      font: {
        family: 'Arial, sans-serif',
        size: 20,
        color: 'black',
        weight: 'bold',
      },
    },
    xaxis: {
      title: 'Date',
      type: 'date',
      rangemode: 'tozero',
    },
    yaxis: {
      title: 'Symptom Value',
    },
    shapes: xPos ? [{
      type: 'line',
      x0: xPos,
      x1: xPos,
      y0: 0,
      y1: Math.max(...filteredData.map(entry => entry.data?.[selectedSymptoms[0]?.value] || 0)),
      line: {
        color: 'green',
        width: 2,
        dash: 'dash',
      },
    }] : [],
    annotations: xPos ? [{
      x: xPos,
      y: Math.max(...filteredData.map(entry => entry.data?.[selectedSymptoms[0]?.value] || 0)),
      xref: 'x',
      yref: 'y',
      text: xPos,
      showarrow: true,
      arrowhead: 2,
    }] : [],
    margin: {
      t: 40,
      r: 10,
      b: 40,
      l: 50,
    },
  };

  const handleMouseMove = (eventData) => {
    if (eventData && eventData.points && eventData.points.length > 0) {
      const date = eventData.points[0].x;
      handleChartHover(date);
    }
  };

  return (
    <div>
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
        config={{ responsive: true }}
        onHover={handleMouseMove}
        onUnhover={handleChartLeave}
      />
    </div>
  );
};

export default SymptomsLineChart;
