import React, { useEffect, useState, useCallback } from "react";
import Plot from 'react-plotly.js';

const LineChartComponent = ({ countyData, selectedCounty, predictionData, displayLSTM, hoverPosition, handleChartHover, handleChartLeave, startInterval, endInterval, onUpdateFilteredData }) => {
  const [filteredCovidData, setFilteredCovidData] = useState([]);
  const [xPos, setXPos] = useState(null);

  const calculateFilteredData = useCallback(() => {
    let chartData = Array.isArray(countyData) ? countyData : countyData.features;
    let predictChartData = Array.isArray(predictionData) ? predictionData : predictionData.features;

    chartData = chartData.map(county => ({
      county_name: county.properties.county_name,
      covid_death: county.properties.covid_death ?? 0,
      data_as_of: county.properties.data_as_of,
    })).sort((a, b) => new Date(a.data_as_of) - new Date(b.data_as_of));

    predictChartData = predictChartData.map(county => ({
      county_name: county.properties.county_name,
      predicted_death: Math.round(county.properties.Predicted_Deaths) ?? 0,
      data_as_of: county.properties.date,
    })).sort((a, b) => new Date(a.data_as_of) - new Date(b.data_as_of));

    const mergedChartData = chartData.map(actualEntry => {
      const matchingPredictedEntry = predictChartData.find(predictedEntry =>
        predictedEntry.county_name === actualEntry.county_name &&
        predictedEntry.data_as_of === actualEntry.data_as_of
      );

      return {
        ...actualEntry,
        predicted_death: matchingPredictedEntry ? matchingPredictedEntry.predicted_death : 0,
      };
    });

    const filteredData = mergedChartData.filter(entry =>
      new Date(entry.data_as_of) >= new Date(startInterval) &&
      new Date(entry.data_as_of) <= new Date(endInterval)
    );

    setFilteredCovidData(filteredData);
    onUpdateFilteredData(filteredData);
  }, [startInterval, endInterval, countyData, predictionData, onUpdateFilteredData]);

  useEffect(() => {
    calculateFilteredData();
  }, [calculateFilteredData]);

  useEffect(() => {
    if (hoverPosition && filteredCovidData.length > 0) {
      const hoverDate = new Date(hoverPosition);
      let closestDataPoint = null;
      let minDiff = Infinity;

      filteredCovidData.forEach(entry => {
        const entryDate = new Date(entry.data_as_of);
        const diff = Math.abs(entryDate - hoverDate);
        if (diff < minDiff) {
          minDiff = diff;
          closestDataPoint = entry;
        }
      });

      if (closestDataPoint) {
        setXPos(closestDataPoint.data_as_of);
      } else {
        setXPos(null);
      }
    }
  }, [hoverPosition, filteredCovidData]);

  // Prepare the data for Plotly
  const data = [
    {
      type: 'scatter',
      mode: 'lines',
      x: filteredCovidData.map(entry => entry.data_as_of),
      y: filteredCovidData.map(entry => entry.covid_death),
      name: 'Actual COVID Deaths',
      line: { color: 'blue' },
    },
    ...(displayLSTM ? [{
      type: 'scatter',
      mode: 'lines',
      x: filteredCovidData.map(entry => entry.data_as_of),
      y: filteredCovidData.map(entry => entry.predicted_death),
      name: 'Prediction (LSTM)',
      line: { color: 'green' },
    }] : []),
  ];

  const layout = {
    title: {
      text: selectedCounty,
      font: {
        size: 20,  // Adjust the size as needed
        color: '#000',  // Adjust the color as needed
        family: 'Arial, sans-serif', // Adjust the font family as needed
        weight: 'bold' // Set font weight to bold
      }
    },
    xaxis: {
      type: 'date',
      rangemode: 'tozero',
    },
    yaxis: {
      title: 'Deaths',
    },
    shapes: xPos ? [{
      type: 'line',
      x0: xPos,
      x1: xPos,
      y0: 0,
      y1: Math.max(...filteredCovidData.map(entry => entry.covid_death)),
      line: {
        color: 'green',
        width: 2,
        dash: 'dash',
      },
    }] : [],
    annotations: xPos ? [{
      x: xPos,
      y: Math.max(...filteredCovidData.map(entry => entry.covid_death)),
      xref: 'x',
      yref: 'y',
      text: xPos,
      showarrow: true,
      arrowhead: 2,
    }] : [],
    margin: {
      l: 40, // Left margin
      r: 20, // Right margin
      t: 40, // Top margin
      b: 60  // Bottom margin
    }
  };

  return (
    <div>
      <Plot
        data={data}
        layout={layout}
        onHover={e => {
          if (e.points.length > 0) {
            handleChartHover(e.points[0].x);
          }
        }}
        onUnhover={() => handleChartLeave()}
      />
    </div>
  );
};

export default LineChartComponent;
