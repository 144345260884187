import React, { useRef, useEffect, useState } from 'react';
import Plotly from 'plotly.js-dist';

const extractYear = (dateString) => new Date(dateString).getFullYear();
const extractMonth = (dateString) => new Date(dateString).getMonth() + 1;
const getWeekNumber = (date) => {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return Math.ceil((date.getDate() + startOfMonth.getDay()) / 7);
};

const yearColorMapping = {
  2020: '#faab63',
  2021: '#b67909',
  2022: '#ef553b',
  2023: '#00cc96',
};

const aggregateMonthlyData = (data) => {
  const monthlyData = {};
  data.features.forEach((entry) => {
    const year = extractYear(entry.start_week);
    const month = extractMonth(entry.start_week);
    const value = entry.new_cases;
    if (!monthlyData[year]) {
      monthlyData[year] = Array(12).fill(0);
    }
    monthlyData[year][month - 1] += value;
  });
  return monthlyData;
};

const aggregateWeeklyDataByMonth = (data) => {
  const weeklyDataByMonth = {};
  data.features.forEach((entry) => {
    const year = extractYear(entry.start_week);
    const month = extractMonth(entry.start_week);
    const value = entry.new_cases;
    const week = getWeekNumber(new Date(entry.start_week));
    if (!weeklyDataByMonth[year]) {
      weeklyDataByMonth[year] = {};
    }
    if (!weeklyDataByMonth[year][month]) {
      weeklyDataByMonth[year][month] = Array(5).fill(0);
    }
    weeklyDataByMonth[year][month][week - 1] += value;
  });
  return weeklyDataByMonth;
};

const AggLineChartComponent = ({ casesData, setSelectedYear, selectedYear, handleChartHover, handleChartLeave }) => {
  const plotRef = useRef(null);
  const [monthlyData, setMonthlyData] = useState({});
  const [weeklyDataByMonth, setWeeklyDataByMonth] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedMonth(null); // Reset month when year changes
  };
  


  useEffect(() => {
    setMonthlyData(aggregateMonthlyData(casesData));
    setWeeklyDataByMonth(aggregateWeeklyDataByMonth(casesData));
  }, [casesData]);

  useEffect(() => {
    if (plotRef.current) {
      let lines = [];
      let layout = {};

      if (selectedYear) {
        const [year, month] = selectedYear.split('-');
        const dataForSelectedYear = weeklyDataByMonth[year] || {};

        if (month) {
          const dataForSelectedMonth = dataForSelectedYear[parseInt(month)] || [];

          lines = [
            {
              x: Array.from({ length: dataForSelectedMonth.length }, (_, i) => `Week ${i + 1}`),
              y: dataForSelectedMonth,
              mode: 'lines+markers',
              name: `Month ${month}`,
            },
          ];

          layout = {
            title: `Weekly Cases for Year ${year}, Month ${month}`,
            xaxis: {
              title: 'Weeks',
              tickvals: Array.from({ length: dataForSelectedMonth.length }, (_, i) => i + 1),
              ticktext: Array.from({ length: dataForSelectedMonth.length }, (_, i) => `Week ${i + 1}`),
            },
            yaxis: { title: 'Cases' },
            showlegend: true,
            width: 800,
            height: 300,
            margin: { t: 20, r: 20, b: 40, l: 40 },
          };
        } else {
          lines = Object.keys(dataForSelectedYear).map((month) => {
            const values = dataForSelectedYear[month];
            return {
              x: Array.from({ length: values.length }, (_, i) => `Week ${i + 1}`),
              y: values,
              mode: 'lines+markers',
              name: `Month ${month}`,
            };
          });

          layout = {
            title: `Weekly Cases for Year ${year}`,
            xaxis: {
              title: 'Weeks',
              tickvals: Array.from({ length: 5 }, (_, i) => i + 1),
              ticktext: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
            },
            yaxis: { title: 'Cases' },
            showlegend: true,
            width: 800,
            height: 300,
            margin: { t: 20, r: 20, b: 40, l: 40 },
          };
        }
      } else {
        lines = Object.keys(monthlyData).map((year) => ({
          x: Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`),
          y: monthlyData[year],
          mode: 'lines+markers',
          name: `Year ${year}`,
          line: { color: yearColorMapping[year] },
        }));

        layout = {
          title: 'Monthly Cases by Year',
          xaxis: {
            title: 'Month',
            tickvals: Array.from({ length: 12 }, (_, i) => i + 1),
            ticktext: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          yaxis: { title: 'Cases' },
          showlegend: true,
          width: 800,
          height: 300,
          margin: { t: 20, r: 20, b: 40, l: 40 },
        };
      }

      Plotly.newPlot(plotRef.current, lines, layout);

      // Adding hover event listener
   
      plotRef.current.on('plotly_hover', (data) => {
  if (data.points.length > 0) {
    const hoveredWeekIndex = data.points[0].x; // Get the hovered week index (e.g., "Week 1")
    const weekNumber = parseInt(hoveredWeekIndex.split(' ')[1]); // Extract the week number (1-5)
    const year = selectedYear; // Get the selected year (e.g., "2021")
    const month = data.points[0].curveNumber + 1; // Assuming `curveNumber` corresponds to the month (adjust if necessary)
    
    if (year && month) {
      const monthNumber = parseInt(month); // Convert the month to a number

      // Calculate the first day of the selected month
      const firstDayOfMonth = new Date(year, monthNumber - 1, 1); // Month is 0-indexed

      // Calculate the date by adding the correct number of days based on the week number
      const date = new Date(firstDayOfMonth);
      date.setDate(date.getDate() + (weekNumber - 1) * 7);

      // Ensure the date is within the correct month
      if (date.getMonth() + 1 === monthNumber) {
        // Format the date as YYYY-MM-DD
        const dateString = date.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD' format

        console.log(`The day: ${firstDayOfMonth}`); // Log for debugging
        console.log(`The month: ${month}`); // Log for debugging
        console.log(`The year: ${year}`); // Log for debugging
        console.log(`Hovering on: ${dateString}`); // Log for debugging
        handleChartHover(dateString); // Pass this valid date string to the parent
      } else {
        console.warn('Calculated date is outside the selected month');
      }
    } else {
      console.error('Selected year or month is not defined');
    }
  }
});

      
      


      // Adding event listener for when the mouse leaves the chart
      plotRef.current.on('plotly_unhover', () => {
        handleChartLeave(); // Reset hover position when unhovering
      });
    }
  }, [selectedYear, monthlyData, weeklyDataByMonth, handleChartHover, handleChartLeave]);

  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold', fontSize: '24px' }}>
        Oklahoma
      </div>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <select onChange={(e) => setSelectedYear(e.target.value)} value={selectedYear || ''}>
          <option value="" disabled>Select a year</option>
          {Object.keys(weeklyDataByMonth).map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <button onClick={() => setSelectedYear(null)} style={{ marginLeft: '10px' }}>Show All Years</button>
      </div>
      <div id="plotly-div" ref={plotRef} />
    </div>
  );
};

export default AggLineChartComponent;
