import React, { useEffect, useState } from "react";
import axios from "axios";
import USMap from "./USMap";

const App = () => {
  const [countyBorders, setCountyBorders] = useState([]);
  const [center, setCenter] = useState([37.0902, -95.7129]); // Center of the USA
  const [zoom, setZoom] = useState(4);

  return (
    <div>
      <USMap center={center} zoom={zoom} />
    </div>
  );
};

export default App;
