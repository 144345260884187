import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { COLORS } from './style';
import { format } from 'd3-format';

const GridmetLineChart = ({
  gridmetData,
  selectedCounty,
  selectedGridmet,
  hoverPosition,
  handleChartHover,
  handleChartLeave,
  startInterval,
  endInterval,
  onUpdateFilteredData
}) => {
  const [filteredClimateData, setFilteredClimateData] = useState([]);
  const [xPos, setXPos] = useState(null);

  const calculateFilteredData = useCallback(() => {
    const selectedCountyData = gridmetData.features.filter(
      (county) => ((county.district + " County" === selectedCounty) || (county.district === selectedCounty)) && (county.district !== null)
    );

    const gridmetChartData = selectedGridmet.flatMap(selectedGridmet => {
      const gridmetName = selectedGridmet?.value;
      if (gridmetName) {
        return selectedCountyData.map(entry => ({
          name: gridmetName,
          date: entry.date,
          value: entry.gridmet[gridmetName] || 0
        }));
      }
      return [];
    });

    const chartData = Object.values(
      gridmetChartData.reduce((acc, gridMetData) => {
        const { name, value, date } = gridMetData;
        if (!acc[name]) {
          acc[name] = { name, data: [] };
        }
        acc[name].data.push({ value: value || 0, date });
        return acc;
      }, {})
    ).map(series => {
      series.data.sort((a, b) => new Date(a.date) - new Date(b.date));
      return series;
    });

    const combinedData = {};
    chartData.forEach(series => {
      series.data.forEach(dataPoint => {
        const date = dataPoint.date;
        if (!combinedData[date]) {
          combinedData[date] = { date, data: {} };
        }
        combinedData[date].data[series.name] = dataPoint.value;
      });
    });

    const mergedData = Object.values(combinedData).filter(entry =>
      new Date(entry.date) >= new Date(startInterval) &&
      new Date(entry.date) <= new Date(endInterval)
    );

    setFilteredClimateData(mergedData);
    onUpdateFilteredData(mergedData);
  }, [gridmetData, selectedCounty, selectedGridmet, startInterval, endInterval, onUpdateFilteredData]);

  useEffect(() => {
    calculateFilteredData();
  }, [calculateFilteredData]);

  useEffect(() => {
    if (hoverPosition && filteredClimateData.length > 0) {
      const hoverDate = new Date(hoverPosition);
      let closestDataPoint = null;
      let minDiff = Infinity;

      filteredClimateData.forEach(entry => {
        const entryDate = new Date(entry.date);
        const diff = Math.abs(entryDate - hoverDate);
        if (diff < minDiff) {
          minDiff = diff;
          closestDataPoint = entry;
        }
      });

      if (closestDataPoint) {
        setXPos(closestDataPoint.date);
      } else {
        setXPos(null);
      }
    }
  }, [hoverPosition, filteredClimateData]);

  const data = selectedGridmet.map((gridMET, index) => ({
    type: 'scatter',
    mode: 'lines',
    x: filteredClimateData.map(entry => entry.date),
    y: filteredClimateData.map(entry => entry.data?.[gridMET.value] || 0),
    name: gridMET.label,
    line: { color: COLORS[index % COLORS.length] },
  }));

  const layout = {
    title: {
      text: selectedCounty,
      font: {
        size: 20,
        color: '#000',
        family: 'Arial, sans-serif',
        weight: 'bold'
      }
    },
    xaxis: {
      type: 'date',
      tickformat: '%b %Y',
    },
    yaxis: {
      title: 'Value',
    },
    shapes: xPos ? [{
      type: 'line',
      x0: xPos,
      x1: xPos,
      y0: 0,
      y1: 1,
      xref: 'x',
      yref: 'paper',
      line: {
        color: 'green',
        width: 2,
        dash: 'dash'
      }
    }] : [],
    annotations: xPos ? [{
      x: xPos,
      y: Math.max(...filteredClimateData.map(entry => Math.max(...Object.values(entry.data)))),
      xref: 'x',
      yref: 'y',
      text: xPos,
      showarrow: true,
      arrowhead: 2,
      ax: 0,
      ay: -40,
    }] : [],
    margin: {
      l: 40,
      r: 20,
      t: 40,
      b: 60
    }
  };

  return (
    <div>
      <Plot
        data={data}
        layout={layout}
        onHover={e => {
          if (e.points.length > 0) {
            handleChartHover(e.points[0].x);
          }
        }}
        onUnhover={() => handleChartLeave()}
      />
    </div>
  );
};

export default GridmetLineChart;