
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ReferenceLine, ResponsiveContainer, Text } from "recharts";
import { DateTime } from 'luxon';
import USMap from './USMap';
import { COLORS } from './style';

// State to store filtered data
let newChartData = [];
let chartData = [];
let xPos;
let seriesData = [];
let filteredChartData = [];

const calculateFilteredData = (chartData, startInterval, endInterval) => {
  return chartData.filter(entry => {
    const entryDate = entry.date;
    return entryDate >= startInterval && entryDate <= endInterval;
  });
};

const HumanWastewaterChartComponent = ({ wastewaterData, selectedCounty, selectedPathogens, hoverPosition, handleChartHover, handleChartLeave, startInterval, endInterval, onUpdateFilteredData, filteredWastewaterDataProp }) => {
//  const [filteredWastewaterData, setFilteredWastewaterData] = useState([]);
const [filteredData, setFilteredData] = useState([]); // Initialize filteredData state
  const [hoveredData, setHoveredData] = useState(null); // Hovered data state

 // console.log("humanWastewaterData from wastewaterLineChart.js:", wastewaterData);
//  console.log("selectedCounty from humanWastewaterLineChart.js:", selectedCounty);
 // console.log("selectedPathogens from humanWastewaterLineChart.js:", selectedPathogens);

  // Function to handle mouse hover events
  const handleMouseMove = (e) => {
    if (e >= 0 && e < filteredChartData.length) {
      const date = filteredChartData[e].date;
  //    console.log("date in humanWastewaterLineChart: ", date);
      handleChartHover(date);
    }
  };

  // Prepare data for Line Chart
  const selectedCountyData = useMemo(() => {
    return wastewaterData.features.filter(
      (county) => (county.county_name + " County" === selectedCounty) && (county.county_name !== null)
    );
  }, [wastewaterData, selectedCounty]);

//  console.log("selectedCountyData from humanWastewaterLineChart", selectedCountyData);

const humanChartData = useMemo(() => {
  return selectedPathogens.flatMap(selectedPathogen => {
    const pathogenName = selectedPathogen?.value;
    if (pathogenName) {
      const data = selectedCountyData.map(entry => {
        const date = entry.week_start;
        const value = entry.pathogen_count;
        return { name: pathogenName, date, value };
      });
      return data;
    }
    return [];
  });
}, [selectedCountyData, selectedPathogens]);

//  console.log("humanChartData from humanWastewaterLineChart.js:", humanChartData);

  // Group data by pathogenName and create a series for each pathogen
  const chartData = useMemo(() => {
    let newChartData = Object.values(
      humanChartData.reduce((acc, pathogenData) => {
        const { name, value, date } = pathogenData;
        if (!acc[name]) {
          acc[name] = {
            name,
            data: [],
          };
        }
        acc[name].data.push({
          value: value === null ? 0 : value,
          date,
        });
        return acc;
      }, {})
    );

    newChartData.forEach(series => {
      series.data.sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    const combinedData = {};
    newChartData.forEach(series => {
      series.data.forEach(dataPoint => {
        const date = dataPoint.date;
        if (!combinedData[date]) {
          combinedData[date] = { date, data: {} };
        }
        combinedData[date].data[series.name] = dataPoint.value;
      });
    });

    return Object.values(combinedData);
  }, [humanChartData]);

  
  //console.log("chartData from humanWastewaterLineChart.js:", chartData);
 // console.log("hoverPosition from humanWastewater: ", hoverPosition);

  
  useEffect(() => {
    const filteredData = calculateFilteredData(chartData, startInterval, endInterval);
    setFilteredData(filteredData); // Update filteredData state
    onUpdateFilteredData(filteredData);
  }, [startInterval, endInterval, chartData, onUpdateFilteredData]);

  let dataPoint = null;
  let minDifference = Infinity;
  const hoverPositionDate = new Date(hoverPosition);

  filteredData.forEach(entry => {
    const entryDate = new Date(entry.date);
    const difference = Math.abs(entryDate - hoverPositionDate);
    if (difference < minDifference) {
      dataPoint = entry;
      minDifference = difference;
    }
  });

  if (dataPoint && dataPoint.date) {
    xPos = dataPoint.date;
    console.log("xPos in humanWastewaterLineChart:", xPos);
  } else {
    console.log("dataPoint is null or undefined");
  }

  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: 'bold' }}>
        {selectedCounty}
      </div>

      <ResponsiveContainer width="95%" height="85%">
        <LineChart
          margin={{
            top: 25,
            left: 0,
            right: 10,
            bottom: 0
          }}
          data={filteredData}
          onMouseMove={(e) => handleMouseMove(e.activeTooltipIndex)}
          onMouseLeave={handleChartLeave}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" domain={['2020-01-04', 'auto']} />
          <YAxis domain={[0, 30]} />
          <Tooltip />
          <Legend />
          {selectedPathogens.map((pathogen, index) => (
            <Line
              type="monotone"
              dataKey={`data.${pathogen.value}`}
              name={pathogen.label}
              key={index}
              stroke={COLORS[index % COLORS.length]}
              dot={false}
            />
          ))}
          {hoverPosition !== null && (
            <ReferenceLine
              x={xPos}
              stroke="green"
              strokeDasharray="4 4"
              label={{ value: xPos, position: 'bottom', offset: 25, fontSize: 12, fill: 'red' }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HumanWastewaterChartComponent;
